/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { menuNavigationEvent } from 'src/lib/analytics';

import styles from './UExpress.module.scss';

const UExpress = ({ logoStyles, navigationType }) => (
  <Link href="/">
    <a
      className={classNames(styles['ue-logo'], logoStyles)}
      onClick={
        navigationType
          ? () => menuNavigationEvent(navigationType, 'logo')
          : undefined
      }
      onKeyUp={
        navigationType
          ? () => menuNavigationEvent(navigationType, 'logo')
          : undefined
      }
    >
      UExpress
    </a>
  </Link>
);

UExpress.propTypes = {
  logoStyles: PropTypes.string,
  navigationType: PropTypes.string,
};

UExpress.defaultProps = {
  logoStyles: '',
  navigationType: '',
};

export default UExpress;
