/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
const articleHeaderClickEvent = (header) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'article-header-click',
    clickLocation: 'article',
    articlePositionNum: header.clickPosition,
    clickTitle: header.title,
    columnName: header.featureName,
  });
};

const commentsClickEvent = (position, title, column) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'comments-click',
      articlePositionNum: position,
      articleName: title,
      clickTitle: 'View Comments',
      columnName: column,
    });
  }
};

const contactEvent = (method) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'contact',
    contactMethod: method,
  });
};

const contentInteractionEvent = (action, column, network) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'content-interaction-button',
    interactionAction: action,
    contentType: 'article',
    columnName: column,
    socialNetwork: network,
  });
};

const errorEvent = (code, message) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'error-event',
    errorCode: code,
    errorMessage: message,
  });
};

const menuNavigationEvent = (type, element) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'menu-navigation',
    navigationType: type,
    navigationElement: element,
  });
};

const onPageNavigationEvent = (location, element, title) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'on-page-navigation',
      clickLocation: location,
      onPageNavigationElement: element,
      clickTitle: title,
    });
  }
};

const placementClickEvent = (location, position, title, column) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'placement-click',
      clickLocation: location,
      articlePositionNum: position,
      clickTitle: title,
      onPageNavigationElement: 'link',
      columnName: column,
    });
  }
};

const scrollTrackingEvent = (percentage) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'scroll',
    action: 'scroll',
    scrollPercentage: percentage,
  });
};

const searchEvent = (query) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'site-search',
    searchTerm: query,
  });
};

const subscribeAttemptEvent = (location) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'subscribe-attempt',
    clickLocation: location,
  });
};

const subscribeSuccessEvent = (location) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'subscribe-success',
    clickLocation: location,
  });
};

const unsubscribeSuccessEvent = (location) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'unsubscribe-success',
    clickLocation: location,
  });
};

const virtualPageviewEvent = (referrer, url) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'virtual-pageview',
    referrerUrl: referrer,
    clickUrl: url,
  });
};

export {
  articleHeaderClickEvent,
  commentsClickEvent,
  contactEvent,
  contentInteractionEvent,
  errorEvent,
  menuNavigationEvent,
  onPageNavigationEvent,
  placementClickEvent,
  scrollTrackingEvent,
  searchEvent,
  subscribeAttemptEvent,
  subscribeSuccessEvent,
  unsubscribeSuccessEvent,
  virtualPageviewEvent,
};
